//DO NOT CONNECT TO THIS UNDER ANY CIRCUMSTANCES

// export const firebaseConfig = {
//   apiKey: "AIzaSyC6-N0k7ZzaJKpqlOFYXzi4NQ1LpMT_32g",
//   authDomain: "hot-takes-65ae9.firebaseapp.com",
//   databaseURL: "https://hot-takes-65ae9.firebaseio.com",
//   projectId: "hot-takes-65ae9-APP",
//   storageBucket: "hot-takes-65ae9.appspot.com",
//   messagingSenderId: "363808171345",
//   appId: "1:363808171345:web:5ab4ee15b61b5319531f70",
//   measurementId: "G-9704FM0P88",
// };

export const firebaseConfig = {
  apiKey: "AIzaSyAXMln9MWQoUNmW020aq1pDob4q3uPUJG8",
  authDomain: "hottakes-v2.firebaseapp.com",
  projectId: "hottakes-v2",
  storageBucket: "hottakes-v2.appspot.com",
  messagingSenderId: "592898031284",
  appId: "1:592898031284:web:89a60c34bc5f63fc58a1e1",
  measurementId: "G-8L728P5DQL",
};
